import * as React from "react"
import Layout from "../components/Layout"
import CalendarSlider from "../components/CalendarSlider"
import DailySchedule from "../components/DailySchedule"
import CourseCards from "../components/CourseCards"
import PascendiBannerLayout2 from "../components/PascendiBannerLayout2"

const NotFound = () => {
  return (
    <Layout title={"Pascendi Gregis Catholic Homeschool Support Program"}>
      <div className="home">
        <PascendiBannerLayout2 />
        <section className="about ">
          <div className=" lg:w-1/2 w-10/12 px-5 pb-10 mx-auto text-white z-30">
            <h2 className="text-center w-full font-black text-4xl py-5">
              ABOUT US
            </h2>
            Pascendi Gregis is a private Non Profit Corporation, owned and
            operated by members and parishioners of the SSPX. Pascendi Gregis
            has worked out an agreement to occupy some space on the church
            grounds and to obtain spiritual guidance from the SSPX. This is a
            serious program and unique to our needs as Catholics. We wish that
            all who are interested in joining us consider the time, work and
            dedication of those who have worked hard to put this program
            together as the first step to our very own Catholic School in South
            Florida. Our only focus, as parents, is to provide the fertile
            ground to plant the seeds for the next generation of Holy Priests
            and Catholic Families by assembling a community with the same or
            similar goal in mind, close to the Church of Our Lord Jesus Christ.
            To the best of our abilities, we will do all that is possible to
            make this happen for our children and yours.
          </div>
        </section>

        <section className="courses centerAll px-10">
          <CourseCards />
        </section>

        <section className="calendar flex flex-col lg:flex-row justify-center items-center gap-y-10 gap-x-5 px-4 lg:px-10">
          <CalendarSlider />
          <DailySchedule />
        </section>
        <section className="py-40 flex justify-center items-center flex-col">
          <h2 className="text-center w-full font-black text-4xl py-5">
            CONTACT US
          </h2>
          <a href="mailto:pascendigregis@gmail.com">pascendigregis@gmail.com</a>
          305.306.3509
        </section>
      </div>
    </Layout>
  )
}

export default NotFound
