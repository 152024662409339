import * as React from "react"
import { IconChevronLeft, IconChevronRight } from "../icons/Icons"

const CalendarSlider = () => {
  return (
    <section className=" w-10/12 max-w-[1000px] mx-auto flex justify-center items-center">
      <div className="flex flex-col lg:flex-row w-full py-20">
        <div className="w-full shadow-lg">
          <p className="text-center text-2xl text-white font-thin py-3 bg-[#b4935f]">
            2022
          </p>
          <div className=" flex text-center text-4xl lg:text-5xl text-white font-bold py-5 lg:py-10 bg-[#e3c493] justify-between items-center px-4">
            <IconChevronLeft className="w-8 hover:-translate-x-2 duration-150 cursor-pointer" />
            <p className="">SEPTEMBER</p>
            <IconChevronRight className="w-8 hover:translate-x-2 duration-150 cursor-pointer" />
          </div>
          <ol
            id="grid"
            className="grid grid-cols-7 text-center justify-between bg-[#b4935f] py-3 text-white "
          >
            <li>SUN</li>
            <li>MON</li>
            <li>TUE</li>
            <li>WED</li>
            <li>THU</li>
            <li>FRI</li>
            <li>SAT</li>
          </ol>
          <div>
            <ol className="grid grid-cols-7 calendar-days">
              <li className="grayed"></li>
              <li className="grayed"></li>
              <li className="grayed"></li>
              <li className="grayed"></li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>10</li>
              <li>11</li>
              <li>12</li>
              <li>13</li>
              <li>14</li>
              <li>15</li>
              <li>16</li>
              <li>17</li>
              <li>18</li>
              <li>19</li>
              <li>20</li>
              <li>21</li>
              <li>22</li>
              <li>23</li>
              <li>24</li>
              <li>25</li>
              <li>26</li>
              <li>27</li>
              <li>28</li>
              <li>29</li>
              <li>30</li>
              <li className="grayed"></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CalendarSlider
